// React Required
import React, { lazy, Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
 import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

// Element Layout
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

const PageScrollTop = lazy(() => import('./home/component/PageScrollTop'));
const Home = lazy(() => import('./home/Home'));
const Search = lazy(() => import('./home/Search'));
const Project = lazy(() => import('./home/Projects'));
const ProductsPageOne = lazy(() => import('./home/ProductsPageOne'));
const ProductsPage = lazy(() => import('./home/ProductsPage'));
// const ProductDeatils = lazy(() => import('./home/ProductDetails'));
const ProductDetailsSearch = lazy(() => import('./home/ProductDetailsSearch'));
const error404 = lazy(() => import('./home/error404'));
const Contact = lazy(() => import('./home/Contact'));


// {
//     loading ? <div className='vh-100 vw-100 d-flex align-items-center justify-content-center'>
//     <ScatterBoxLoader
//       primaryColor={"#1bad99"}
//       duration={4}
//     />
//   </div>:
const  Root = ()=>{ 
    // const [loading, setLoading] =useState(false);
    const [x,setX]=useState('this x');
    useEffect(()=>{

    },[])
    window.addEventListener('load', function() {
    })
        return(
            <div>
            <BrowserRouter basename={'/'}>
            <Suspense fallback={<div>Loading... </div>}>

                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/projects`} component={Project}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/expansion-joint-profile`} component={ProductsPageOne}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/control-joint-profile`} component={ProductsPageOne}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/cover-expansion-joint-profile`} component={ProductsPageOne}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/rubber-guard`} component={ProductsPageOne}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/handrail-system`} component={ProductsPageOne}/>
                        <Route x={x} setX={setX} exact path={`${process.env.PUBLIC_URL}/search/:product`} component={Search}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product-details/:productName`} component={ProductDetailsSearch}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product/:productName`} component={ProductsPage}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/products/:productName`} component={ProductDetailsSearch}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>
                    </Switch>
                </PageScrollTop>
                </Suspense>

            </BrowserRouter>
            </div>
        )
    }

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();